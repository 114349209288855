import React from "react"
import { graphql } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper"
import { getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"

// install Swiper modules
SwiperCore.use([Navigation])

const MainSlider2 = props => {
  return (
    <div className="">
      <>
        <Fade bottom cascade>
          <Swiper
            slidesPerView={4}
            speed={1000}
            spaceBetween={0}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              1536: {
                slidesPerView: 4,
              },
              1280: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
              },
              0: {
                slidesPerView: 1,
              },
            }}
            pagination={{
              el: ".my-custom-pagination-div-2",
              clickable: true,
              renderBullet: (index, className) => {
                return '<span class="' + className + '"> </span>'
              },
            }}
            navigation={{
              nextEl: ".swiper-button-next-square-2",
              prevEl: ".swiper-button-prev-square-2",
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {props.slider_array.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="overflow-hidden">
                    {props.gif ? (
                      <>
                        <div
                          className="py-60 bg-cover"
                          style={{ backgroundImage: `url(${item.image})` }}
                        ></div>
                      </>
                    ) : (
                      <>
                        <BackgroundImage
                          {...item.image}
                          className="py-60 bg-red-400 hover:scale-110 duration-500"
                        ></BackgroundImage>
                      </>
                    )}
                  </div>
                  <div className="pr-10">
                    <div
                      className={
                        "mt-10 text-mint text-2xl text-center sm:text-left"
                      }
                    >
                      {item.title}
                    </div>
                    <div className="mt-6 text-white text-base text-center sm:text-left">
                      {item.content}
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Fade>
      </>
    </div>
  )
}

export default MainSlider2
