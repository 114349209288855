// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import { ArrowNarrowRightIcon, BeakerIcon } from "@heroicons/react/solid"
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import MainSlider from "../components/common/main-slider"
import MainSlider2 from "../components/common/main-slider-same-page"
import AtglanceSlider from "../components/common/at-glance-slider"
import con_cloth from "../images/home/Unlocking-New-dimensions/Connected_Clothing.gif"
import wearable from "../images/home/Unlocking-New-dimensions/Wearables.gif"
import con_device from "../images/home/Unlocking-New-dimensions/Connected_Devices.png"

import hero_video from "../videos/hero.mp4"
import IndexSlider from "../components/common/index-slider"
import { GatsbyImage } from "gatsby-plugin-image"

const Home = ({ data }) => {
  const images = {
    hero: convertToBgImage(getImage(data.hero)),
    grid_1: convertToBgImage(getImage(data.grid_1)),
    grid_2: convertToBgImage(getImage(data.grid_2)),
    grid_3: convertToBgImage(getImage(data.grid_3)),
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
    dusk_texture: convertToBgImage(getImage(data.dusk_texture)),
    slider_1: convertToBgImage(getImage(data.slider_1)),
    slider_2: convertToBgImage(getImage(data.slider_2)),
    slider_3: convertToBgImage(getImage(data.slider_3)),

    // your end to end carosoul

    concept_img: convertToBgImage(getImage(data.end_to_end_1)),
    configure_img: convertToBgImage(getImage(data.end_to_end_2)),
    industrial_img: convertToBgImage(getImage(data.end_to_end_3)),
    manufacture_img: convertToBgImage(getImage(data.end_to_end_4)),
    proprietary_img: convertToBgImage(getImage(data.end_to_end_5)),

    test1: getImage(data.end_to_end_5),

    // success story images

    dreem: convertToBgImage(getImage(data.success1)),
    lifesense: convertToBgImage(getImage(data.success2)),
    nadix: convertToBgImage(getImage(data.success3)),
    napapirinji: convertToBgImage(getImage(data.success4)),
    nivia: convertToBgImage(getImage(data.success5)),
    odlo: convertToBgImage(getImage(data.success6)),
  }

  const testimonial = [
    {
      quote:
        "“I-Thermic gives you freedom and lightness, so your ski-day will not be ruined.”",
      author: " – Timo Pape, OLDO Brand Director",
      bold: "Smart heating baselayer with temperature sensing",
      description:
        "In collaboration with the outdoor brand Odlo, our proprietary heating technology was integrated into a washable mid-layer for skiwear.",
      image: images.odlo,
    },
    {
      quote: "",
      author: "",
      bold: "Rainwear jacket with an illuminated logo",
      description:
        "Integrating a light array into a raincoat, was a challenge that we were eager to undertake. By integrating our lighting technology onto the sleeve of a raincoat for the Napapijiri brand under the VF Corporation, we were able to create an eye-catching and functional piece of clothing that illuminated their brand logo and created excitement around the brand. (Remove the rest of the sentences).",
      image: images.napapirinji,
    },
    {
      quote:
        "“What was interesting about this project was the use of the latest technologies to improve our products and the ability to quickly make changes to prototypes.  It helped shape the development process in a goal-oriented manner.”",

      author: "– Beiersdorf team",
      bold: "Sweat-sensing t-shirt",
      description:
        "In cooperation with Beiersdorf, we developed a t-shirt that measures sweat, to better understand individual sweating and the effects of NIVEA antiperspirant products.",
      image: images.nivia,
    },
    // {
    //   quote: "",

    //   author: "",
    //   bold: "Smart Sleep Headband",
    //   description:
    //     "Connected headband, worn at night, aimed at improving sleep and monitoring brain activity.",
    //   image: images.dreem,
    // },
    {
      quote:
        "“The Softmatter team helped LifeSense provide a high quality, comfortable and stylish product for women, using the latest production technologies.“",
      author: "- Lifesense Group",
      bold: "Smart urinary incontinence underwear",
      description:
        "In partnership with Carin, we developed patented smart underwear that pairs with a wearable sensor and tracking app, to create a tailored exercise program designed to eliminate leaks in a matter of weeks. The Softmatter team helped LifeSense provide a high quality and comfortable product for women using the latest production technologies.",
      image: images.lifesense,
    },
    {
      quote: "",
      author: "",
      bold: "Technology to guide the practice of yoga",
      description:
        "Nadi X yoga experience provides guidance on the body as well as audio feedback, like a personal instructor. Founded by Wearable X, the smart yoga pants and app guide the wearer with gentle vibration to draw attention to the areas of the body the wearer needs to focus. Softmatter by MAS as investors in Wearable X worked with the team to integrate 10 haptic modules and 5 accelerometers into a wash and dry stretch garment. These sensors allow the garment to track both position and movement - metrics that are communicated to the user's phone via integrated Bluetooth. The corresponding software analyses the wearer's position and indicates at the end of each pose sequence how successful the wearer's posture is in each pose. ",
      image: images.nadix,
    },
    {
      quote: "",
      author: "",
      bold: "",
      description:
        "We’d love to show you some more of our products, but due to the NDAs we signed, we are unable to. We’ll have to leave it to your imagination!",
      image: images.hero,
    },
  ]

  const [active, setActive] = useState(0)

  const successStories = [
    {
      title: "Odlo I-Thermic",
    },
    {
      title: "Napapijri - VF",
    },
    {
      title: "Nivea",
    },
    // {
    //   title: "Dreem",
    // },
    {
      title: "Lifesense",
    },

    {
      title: "Nadi X",
    },
    {
      title: "Other",
    },
  ]

  const slider = [
    {
      title: "Sketch to Shelf",
      content: "Enabling value creation from ideation to scaled manufacturing",
      image: images.concept_img,
    },
    {
      title: "Proprietary development process",
      content:
        "Processes that focuses on robust products designed for manufacturability at scale",
      image: images.proprietary_img,
    },
    {
      title: "Configurable Technology Stacks",
      content:
        "Patented Technology platforms that enable a multitude of applications",
      image: images.configure_img,
    },
    {
      title: "Industrial Standards",
      content:
        "ISO 9001, 14001 and 13485, OHSAS 18001 and LEED compliant manufacturing facilities globally.",
      image: images.industrial_img,
    },
    {
      title: "Manufacturing",
      content:
        "Leveraging automation to create precision manufacturing at scale",
      image: images.manufacture_img,
    },
  ]
  const slider2 = [
    {
      title: "Wearables",
      content:
        "Leveraging soft goods to create next- tbody-contouredtable, body contoured wearables with superior aesthetics.",
      image: wearable,
    },
    {
      title: "Connected Clothing",
      content:
        "Creating digital experiences for consumers using the integration of sensors to track metrics and actuators to provide feedback on clothing",
      image: con_cloth,
    },
    {
      title: "Connected Devices",
      content:
        "Enhancing the interface of consumer devices and interaction with users leveraging textile engineering and integrated technologies",
      image: con_device,
    },
    // {
    //   title: "Manufacturing",
    //   content:
    //     "Our innovative manufacturing solutions merge craftsmanship with the replicability and scale required for consumer electronics.",
    //   image: images.slider_1,
    // },
  ]

  // console.log(active)

  const slider3 = [
    {
      topic: "4",
      desc: "Countries",
    },
    {
      topic: "ISO 9001 ",
      desc: " and ISO 13485 COMPLIANT",
    },
    {
      topic: "50",
      desc: "Products Launched",
    },

    {
      topic: "25+",
      desc: "Patents",
    },
  ]

  return (
    <Layout>
      <Seo title="Home" />
      <section>
        <BackgroundImage {...images.hero} className="h-screen bg-dusk ">
          <video
            className="object-cover h-full w-full absolute lg:block hidden"
            autoPlay
            muted
            loop
            playsInline
          >
            <source src={hero_video} />
          </video>
          <div className="container h-full mx-auto relative">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col sm:items-start items-center w-full">
                  <div className="sm:text-6xl text-4xl mb-1 text-white sm:text-left text-center">
                    Transforming the future of
                  </div>
                  <div className="sm:text-6xl text-4xl text-white sm:text-left text-center">
                    Wearable Technology
                  </div>
                  <div className="sm:text-2xl text-xl text-white sm:text-left text-center font-light mt-4">
                    Through textile integration
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-white">
          <div className="container mx-auto py-20">
            <div className="flex flex-col items-center">
              <Fade top>
                <div className="mb-8">
                  <StaticImage
                    alt="Softmatter"
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    width={30}
                    quality={100}
                  />
                </div>
              </Fade>
              <Fade bottom cascade>
                <div>
                  <div className="text-4xl text-center font-light mb-4">
                    We are Softmatter
                  </div>
                  <div className="text-2xl font-light text-center max-w-5xl leading-snug">
                    We  leverage human-centered design and textile integrations
                    to reimagine the interfaces of products transforming the
                    user experience’
                    {/* <div>
                      goods and wearables. Driven by this vision, we work
                      towards crafting complete
                    </div>
                    next-to-skin solutions that transform the way we live. */}
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <div className="flex lg:flex-row flex-col gap-14 lg:mb-80">
              <div className="w-full lg:w-5/12">
                <Fade bottom cascade>
                  <div className=" text-white mb-10 lg:mb-0">
                    <Fade top>
                      <div className="uppercase text-xl font-light mb-2 sm:text-left text-center">
                        What we offer
                      </div>
                    </Fade>
                    <div className="text-4xl font-light mb-4 lg:max-w-xs  sm:text-left text-center">
                      Your end-to-end solution provider for all things smart.
                    </div>
                    <div className="text-xl lg:max-w-sm sm:text-left text-center lg:mr-20">
                      Combining years of expertise in building next-to-skin
                      solutions with configurable technology stacks we enable a
                      new generation of wearable technology devices
                    </div>
                    <div className="flex justify-center sm:justify-start">
                      <Link to="/services">
                        <button className="uppercase px-8 py-4 border border-white mt-8 hover:bg-white/20 cursor-pointer duration-200">
                          Learn more
                        </button>
                      </Link>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="lg:w-7/12 w-full lg:absolute lg:right-0">
                <div className="">
                  <IndexSlider slider_array={slider} />
                  <div className="flex items-center mt-24 justify-center">
                    <div className="text-white my-custom-pagination-div"></div>
                    <div className="flex justify-center mr-20">
                      <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronLeftIcon width={20} />
                      </div>
                      <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronRightIcon width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="text-white absolute w-3/5 right-0">
                <IndexSlider slider_array={slider} />
                <div className="flex pr-0 sm:pr-20 lg:pr-96 mr-0 sm:mr-20 md:mr-60 xl:mr-80 2xl:mr-96  justify-center items-center mt-14">
                  <div className="text-white my-custom-pagination-div ml-0 xl:ml-16 2xl:ml-24"></div>
                  <div className="flex justify-center mr-20">
                    <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                      <ChevronLeftIcon width={20} />
                    </div>
                    <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                      <ChevronRightIcon width={20} />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-mint">
          <div className="container mx-auto py-14">
            <Fade top>
              <div>
                <div className="text-dusk text-xl sm:text-left text-center">
                  AT A GLANCE
                </div>
              </div>
            </Fade>

            <AtglanceSlider slider3={slider3} />

            {/* <Fade bottom cascade>
              <div className="grid grid-cols-3 gap-4 mt-20 text-dusk">
                <div className="text-center">
                  <div className="text-5xl">Pioneers</div>
                  <div className="text-2xl mt-2">
                    of Smart soft goods, with a portfolio of patented
                    technologies.
                  </div>
                </div>
                <div className="text-center">
                  <div className="text-5xl">50+</div>
                  <div className="text-2xl mt-2">Products Launched</div>
                </div>
                <div className="text-center">
                  <div className="text-5xl">Largest</div>
                  <div className="text-2xl mt-2">
                    smart apparel manufacturer
                  </div>
                </div>
              </div>
            </Fade> */}
          </div>
        </div>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-24">
            <Fade bottom cascade>
              <div className="text-dusk">
                <div className="text-center text-xl sm:text-2xl font-firs-thin uppercase mb-4">
                  THE TECHNOLOGY
                </div>
                <div className="text-center text-5xl sm:text-6xl mb-4">
                  Platforms
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  A collection of passive and active technologies that have been
                  built via strong consumer research, to solve for real needs.
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/platforms">
                    <button className="px-8 py-4 bg-dusk rounded-3xl  hover:bg-dusk/90 duration-200 text-white text-2xl">
                      Explore all Platforms
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
            <div className="grid sm:grid-cols-3 grid-cols-1 sm:gap-3 gap-10 mt-20">
              <Fade bottom cascade>
                <div className="">
                  <div>
                    <StaticImage
                      alt="Softmatter"
                      src="../images/home/1.jpeg"
                      placeholder="blurred"
                    />
                  </div>
                  <div className="mt-6 text-dusk">
                    <div className="text-2xl mb-2 text-center font-firs-medium">
                      Textile Engineering
                    </div>
                    <div className="text-xl mb-2 text-center">
                      Powering soft textile Integration
                    </div>
                    <div className="flex justify-center">
                      <Link to="/textile-engineering">
                        <button className="uppercase px-8 py-4 border border-dusk mt-3 hover:bg-dusk/20 cursor-pointer duration-200">
                          Learn more
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div>
                    <StaticImage
                      alt="Softmatter"
                      src="../images/home/2.jpeg"
                      placeholder="blurred"
                    />
                  </div>
                  <div className="mt-6 text-dusk">
                    <div className="text-2xl mb-2 text-center font-firs-medium">
                      Integrated Technologies
                    </div>
                    <div className="text-xl mb-2 text-center">
                      Integrating the smart to the soft
                    </div>
                    <div className="flex justify-center">
                      <Link to="/integrated-technologies">
                        <button className="uppercase px-8 py-4 border border-dusk mt-3 hover:bg-dusk/20 cursor-pointer duration-200">
                          Learn more
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div>
                    <StaticImage
                      alt="Softmatter"
                      src="../images/home/3.jpeg"
                      placeholder="blurred"
                    />
                  </div>
                  <div className="mt-6 text-dusk">
                    <div className="text-2xl mb-2 text-center font-firs-medium">
                      Advanced Materials
                    </div>
                    <div className="text-xl mb-2 text-center">
                      Elevated circuitry and materiality
                    </div>
                    <div className="flex justify-center">
                      <Link to="/advanced-materials">
                        <button className="uppercase px-8 py-4 border border-dusk mt-3 hover:bg-dusk/20 cursor-pointer duration-200">
                          Learn more
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <div className="flex lg:flex-row flex-col lg:mb-80 mb-96 pb-40 sm:pb-20">
              <div className="w-full lg:w-4/12">
                <Fade bottom cascade>
                  <div className=" text-white lg:pr-10">
                    <Fade top>
                      <div className="uppercase text-xl font-light mb-4 text-mint text-center sm:text-left">
                        APPLICATIONS
                      </div>
                    </Fade>
                    <div className="text-4xl font-light mb-4 lg:max-w-xs  text-mint text-center sm:text-left">
                      Unlocking New Dimensions of Smarter Living.
                    </div>
                    <div className="text-xl lg:max-w-sm text-white text-center sm:text-left">
                      Our global labs create and accelerate solutions for our
                      customer partners with industry-focused Innovation.
                      Solutions built across a variety of applications
                    </div>
                    <div className="flex sm:justify-start justify-center">
                      <button className="uppercase px-8 py-4 border border-mint mt-8 bg-mint rounded-2xl text-dusk hover:bg-mint/30 hover:text-white cursor-pointer duration-200">
                        Learn more
                      </button>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="lg:w-7/12 w-full lg:absolute lg:right-0">
                <div className="">
                  <IndexSlider slider_array={slider2} gif />
                  <div className="flex items-center mt-24 justify-center">
                    <div className="text-white  my-custom-pagination-div-2"></div>
                    <div className="flex justify-center mr-20">
                      <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronLeftIcon width={20} />
                      </div>
                      <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronRightIcon width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-chalk">
          <div className=" py-24">
            <Fade bottom cascade>
              <div className="text-dusk">
                <div className="text-center text-xl sm:text-2xl font-firs-thin uppercase mb-4">
                  Leading the curve
                </div>
                <div className="text-center text-5xl sm:text-6xl mb-4">
                  Areas of Play
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl px-10 sm:px-20 mx-auto">
                  Pioneering groundbreaking innovations, to craft a smarter
                  tomorrow for a variety of brands
                </div>
                <div className="flex justify-center mt-8  md:w-3/12 lg:w-2/12 mx-auto">
                  <Link to="/applications" className="mx-auto">
                    <button className="px-8 py-4 bg-dusk rounded-3xl hover:bg-dusk/90 duration-200 text-white text-2xl">
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
            <div className="mt-20 sm:text-left text-center overflow-hidden">
              <div className="flex lg:flex-row flex-col">
                <div className="lg:hidden block w-full">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.grid_1}
                        className="sm:py-60 py-44"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-32 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-dusk">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Consumer Health and
                        <div>Wellness</div>
                      </div>
                      <div className="text-xl max-w-xl">
                        Utilizing fabric-integrated sensors and actuators to
                        create next-generation pain management, remote patient
                        monitoring, and sleep solutions.
                      </div>
                      <Link to="/applications">
                        <button className="uppercase px-8 py-4 border border-dusk mt-6 hover:bg-dusk/20 cursor-pointer duration-200">
                          Learn more
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:block hidden">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.grid_1}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="lg:w-1/2 w-full">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.grid_2}
                        className="sm:py-60 py-44 bg-gray-400 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-32 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-dusk">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Consumer Technology
                      </div>
                      <div className="text-xl">
                        Replacement of hard components with fabrics that fuel a
                        softer touch, coupled with high-speed data transfer,
                        touch textiles, and fabric peripherals.
                      </div>
                      <Link to="/applications">
                        <button className="uppercase px-8 py-4 border border-dusk mt-6 hover:bg-dusk/20 cursor-pointer duration-200">
                          Learn more
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="block lg:hidden w-full">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.grid_3}
                        className="sm:py-60 py-44 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-32 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-dusk">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Outdoor and Workwear
                      </div>
                      <div className="text-xl">
                        Leveraging our wide technology collection to build soft
                        goods that allow you to perform at your best, while
                        staying safe.
                      </div>
                      <Link to="/applications">
                        <button className="uppercase px-8 py-4 border border-dusk mt-6 hover:bg-dusk/20 cursor-pointer duration-200">
                          Learn more
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="hidden lg:block lg:w-1/2 w-full">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.grid_3}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk">
          <div className="">
            <div className="flex lg:flex-row flex-col">
              <div className="w-full lg:w-1/2 md:mx-0 mx-auto container md:pl-36">
                <div className="mt-24 mb-24 lg:mb-72 text-white">
                  <Fade top>
                    <div className="uppercase text-2xl font-light mb-10 text-mint">
                      OUR SUCCESS STORIES
                    </div>
                  </Fade>
                  <div className="">
                    {successStories.map((item, index) => {
                      return (
                        <Fade bottom key={index}>
                          <div className="text-4xl flex items-center text-white/60 mb-6">
                            <span
                              className={
                                active == index
                                  ? "text-white duration-200 cursor-pointer flex items-center"
                                  : "hover:pl-4 text-white/50 cursor-pointer hover:text-white duration-200"
                              }
                              onClick={() => setActive(index)}
                            >
                              <ArrowNarrowRightIcon
                                width={30}
                                className={
                                  active == index
                                    ? "mr-5 block text-mint"
                                    : "hidden"
                                }
                              />
                              {item.title}
                            </span>
                          </div>
                        </Fade>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/2 overflow-hidden ">
                <BackgroundImage
                  {...testimonial[active].image}
                  className="text-white h-full"
                >
                  <div className="bg-dusk/80 h-screen lg:h-full">
                    <div className="flex h-full justify-center items-center px-10">
                      <Fade right cascade opposite>
                        <div>
                          <div className="text-2xl max-w-xl mx-auto leading-tight">
                            {testimonial[active].quote}
                          </div>
                          <div className="text-base my-6">
                            {testimonial[active].author}
                          </div>
                          <div className="font-firs-regular text-2xl">
                            {testimonial[active].bold}
                          </div>
                          <div className="max-w-xl text-xl">
                            {testimonial[active].description}
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                </BackgroundImage>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="lg:w-1/2 w-full">
            <BackgroundImage
              {...images.mint_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    width={180}
                    className="lg:w-auto w-20"
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="lg:w-1/2 w-full">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 text-dusk px-10">
                <div className="text-center text-5xl sm:text-6xl mb-4 px-1">
                  Connect with Softmatter
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-mint  hover:bg-mint/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto py-24">
          <Fade top>
            <div className="text-4xl text-center max-w-4xl mx-auto text-dusk">
              Latest news
            </div>
          </Fade>
          <Fade bottom cascade>
            <div className="grid grid-cols-1 sm:text-left text-center lg:grid-cols-3 gap-10 lg:gap-4 mt-20 text-dusk">
              <div className="">
                <div>
                  <StaticImage
                    src="../images/home/Latest-News/Devices.png"
                    placeholder="blurred"
                    alt="Softmatter"
                  />
                </div>
                <div className="mt-5 mb-3 uppercase">
                  PRODUCT and INNOVATION
                </div>
                <div className="pr-5 pb-5 lg:pb-10 font-firs-regular text-xl lg:h-44 2xl:h-36">
                  Reinventing devices for today and tomorrow: Through the lens
                  of wearable technology and smart textiles
                </div>
                <div className="flex items-center justify-center sm:justify-start">
                  <div className="flex items-center cursor-pointer hover:font-firs-regular duration-200">
                    <Link to="/news/reinventing-devices-for-today-and-tomorrow">
                      <div className="cursor-pointer ">Read More</div>
                    </Link>
                    <ChevronRightIcon width={15} className="ml-1" />
                  </div>
                </div>
              </div>
              <div className="">
                <div className="">
                  <div>
                    <StaticImage
                      src="../images/home/Latest-News/AR _ VR.png"
                      placeholder="blurred"
                      alt="Softmatter"
                    />
                  </div>
                  <div className="mt-5 mb-3 uppercase">
                    PRODUCT and INNOVATION
                  </div>
                  <div className="pr-5 pb-5 lg:pb-10 font-firs-regular text-xl lg:h-44 2xl:h-36">
                    Game Changer for AR and VR: Smart Textiles
                  </div>
                  <div className="flex items-center justify-center sm:justify-start">
                    <div className="flex items-center cursor-pointer hover:font-firs-regular duration-200">
                      <Link to="/news/game-changer-for-AR-and-VR">
                        <div className="cursor-pointer ">Read More</div>
                      </Link>
                      <ChevronRightIcon width={15} className="ml-1" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="">
                  <div>
                    <StaticImage
                      src="../images/home/Latest-News/Evolved.png"
                      placeholder="blurred"
                      alt="Softmatter"
                    />
                  </div>
                  <div className="mt-5 mb-3 uppercase">
                    PRODUCT and INNOVATION
                  </div>
                  <div className="pr-5 pb-5 lg:pb-10 font-firs-regular text-xl lg:h-44 2xl:h-36">
                    Evolved from a futuristic accessory to a ubiquitous health &
                    wellness wearable: engineering human factors in smartwatches
                    and wristables
                  </div>
                  <div className="flex items-center justify-center sm:justify-start">
                    <div className="flex items-center cursor-pointer hover:font-firs-regular duration-200">
                      <Link to="/news/evolved-from-a-futuristic-accessory-to-a-ubiquitous-health-&-wellness-wearable">
                        <div className="cursor-pointer ">Read More</div>
                      </Link>
                      <ChevronRightIcon width={15} className="ml-1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query HomeImages {
    hero: file(relativePath: { eq: "home/hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid_1: file(relativePath: { eq: "home/grid_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid_2: file(relativePath: { eq: "home/grid_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid_3: file(relativePath: { eq: "home/grid_3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    dusk_texture: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    mint_texture: file(relativePath: { eq: "textures/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_1: file(relativePath: { eq: "home/slider/slider_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_2: file(relativePath: { eq: "home/slider/slider_2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_3: file(relativePath: { eq: "home/slider/slider_3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    end_to_end_1: file(
      relativePath: { eq: "home/Your end-to-end-Carousel/concept.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    end_to_end_2: file(
      relativePath: { eq: "home/Your end-to-end-Carousel/configure.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    end_to_end_3: file(
      relativePath: { eq: "home/Your end-to-end-Carousel/industrial.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    end_to_end_4: file(
      relativePath: { eq: "home/Your end-to-end-Carousel/Manufacturing.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    end_to_end_5: file(
      relativePath: { eq: "home/Your end-to-end-Carousel/proprietary.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    success1: file(relativePath: { eq: "home/Success-stories/Dreem.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    success2: file(relativePath: { eq: "home/Success-stories/LIfesense.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    success3: file(relativePath: { eq: "home/Success-stories/Nadi_X.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    success4: file(
      relativePath: { eq: "home/Success-stories/Napapiriji.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    success5: file(relativePath: { eq: "home/Success-stories/Nivea.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    success6: file(relativePath: { eq: "home/Success-stories/Odlo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default Home
